import { Slider, SliderItem } from '../common/slider/Slider'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import thumb1 from '../../assets/images/nft/1.png'
import thumb2 from '../../assets/images/nft/2.png'
import thumb3 from '../../assets/images/nft/3.png'
import thumb4 from '../../assets/images/nft/4.png'
import thumb5 from '../../assets/images/nft/5.png'
import thumb6 from '../../assets/images/nft/6.png'

export default function SliderNFT() {
  const slideImages = [thumb1, thumb2, thumb3, thumb4, thumb5, thumb6]

  const sliderSettings = {
    dots: false,
    arrows: false,
    autoplay: true,
    speed: 500,
    fade: true,
    autoplaySpeed: 700,
    centerMode: true,
    centerPadding: '0px',
    infinite: true,
    slidesToShow: 1,
    pauseOnHover: true,
    slidesToScroll: 1,
  }

  return (
    <div className="mt-10">
      <Slider {...sliderSettings}>
        {slideImages?.map((thumb, idx) => (
          <SliderItem key={idx}>
            <div className="border-2 border-gray-600 p-3">
              <img src={thumb} alt="thumb" className="" />
            </div>
          </SliderItem>
        ))}
      </Slider>
    </div>
  )
}
