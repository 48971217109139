module.exports = {
  // eslint-disable-next-line no-loss-of-precision
  MaxMintCount: 100,
  BurnCareAmout: 50000000,
  mintCount: 1,
  mintCost: 0,
  wlcost: 0,
  MaxMintAllow: 5,
  totalGas: 400000,
  RoyalPetsContractAddr: '0x256fe33eb532f5E096804e943f010D982ce89a4c',
  CareTokenAddr: '0x5701113457375f8E78E46238533a27Ba3E375d76',
}
