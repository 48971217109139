import { useState, useEffect } from 'react'
import Countdown from 'react-countdown'

import Button from '../common/button'
import SliderNFT from './Slider'

import MintStyleWrapper from './Mint.style'

import checkIcon from '../../assets/images/icon/mint-right-text-icon.svg'
import twitterIcon from '../../assets/images/icon/Twitter.svg'
import telegramIcon from '../../assets/images/icon/Telegram.svg'
import { GooSpinner } from 'react-spinners-kit'

import config from '../../config/config'
import ROYALPETSABI from '../../assets/abis/royalPetsABI.json'
import CARETOKENABI from '../../assets/abis/careTokenABI.json'
import { useWeb3React } from '@web3-react/core'
const ethers = require('ethers')

const Mint = () => {
  const { account } = useWeb3React()

  const [whiteListState, setWhiteListState] = useState(false)
  const [loadingState, setLoadingState] = useState(false)
  const [mintCount, setMintCount] = useState(0)
  const [mintState, setMintState] = useState(true)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)

  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const Signer = provider.getSigner()

  const royalPetsContract = new ethers.Contract(
    config.RoyalPetsContractAddr,
    ROYALPETSABI,
    Signer,
  )

  const careTokenContrat = new ethers.Contract(
    config.CareTokenAddr,
    CARETOKENABI,
    Signer,
  )

  const mintStateFunc = async () => {
    let balance = 0
    if (account !== undefined) {
      balance = await royalPetsContract.totalSupply()
      const count = Number(balance.toString())
      setMintCount(count)
      if (count >= config.MaxMintCount) {
        setMintState(false)
      }
    }
  }

  useEffect(() => {
    if (account) {
      mintStateFunc()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const mint = async () => {
    setLoadingState(true)
    if (whiteListState) {
      await careTokenContrat
        .approve(
          config.RoyalPetsContractAddr,
          ethers.utils.parseEther(config.BurnCareAmout.toString()),
          {
            gasLimit: 500000,
          },
        )
        .then((tx) => {
          tx.wait().then(() => {
            royalPetsContract
              .mintWithSUPERFLARE(config.mintCount, {
                gasLimit: config.totalGas,
                value: ethers.utils.parseEther(config.wlcost.toString()),
              })
              .then((tx) => {
                tx.wait().then(() => {
                  setLoadingState(false)
                  setShowSuccessAlert(true)
                  setTimeout(() => {
                    setShowSuccessAlert(false)
                  }, 10000)
                  window.location.reload()
                })
              })
          })
        })
    } else {
      await careTokenContrat
        .approve(
          config.RoyalPetsContractAddr,
          ethers.utils.parseEther(config.BurnCareAmout.toString()),
          {
            gasLimit: 500000,
          },
        )
        .then((tx) => {
          tx.wait().then(() => {
            royalPetsContract
              .mintWithSUPERFLARE(config.mintCount, {
                gasLimit: config.totalGas,
                value: ethers.utils.parseEther(config.mintCost.toString()),
              })
              .then((tx) => {
                tx.wait().then(() => {
                  setLoadingState(false)
                  setShowSuccessAlert(true)
                  setTimeout(() => {
                    setShowSuccessAlert(false)
                  }, 10000)
                  window.location.reload()
                })
              })
          })
        })
    }
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <h1 className="font-bold mt-3 text-4xl text-white">0h : 0m : 0s</h1>
      )
    } else {
      // Render a countdown
      return (
        <h1 className="font-bold mt-3 text-4xl text-white">
          {days}d : {hours}h : {minutes}m : {seconds}s
        </h1>
      )
    }
  }

  return (
    <MintStyleWrapper>
      {showSuccessAlert && (
        <div className="absolute bg-red-100 border duration-300 px-10 py-4 rounded text-green-700 top-20 transition-all z-50">
          <span className="block px-5 sm:inline">Minted Successful</span>
          <span className="absolute bottom-0 py-1 right-0 top-0">
            <svg
              className="fill-current h-6 text-green-500 w-6"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
      )}

      <div className="container my-10">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="mint_right_content">
            {mintState ? (
              <>
                <div className="content_header">
                  {!whiteListState && (
                    <h4 className="flex font-extrabold">
                      SUPERFLR TOKEN MINTING
                      <span className="">
                        <img src={checkIcon} alt="icon" />
                      </span>
                    </h4>
                  )}

                  <h1 className="font-bold">
                    {whiteListState ? 'WhiteList' : 'Super Creatures'}
                  </h1>
                </div>
                {whiteListState && (
                  <div className="mint_timer">
                    <h3 className="font-extrabold text-lg text-white">
                      Whitelist Mint End in
                    </h3>
                    <Countdown
                      date={'2023-13-07 09:00:00 UTC'}
                      intervalDelay={0}
                      precision={3}
                      onComplete={() => setWhiteListState(false)}
                      renderer={renderer}
                    />
                  </div>
                )}
                <div className="content_footer">
                  <h1 className="font-bold">
                    Price {config.BurnCareAmout} SUPERFLR
                  </h1>
                </div>
              </>
            ) : (
              <h1 className="flex font-bold tex text-5xl">
                SOLD OUT - Thank you{' '}
                <span className="">
                  <img src={checkIcon} alt="icon" />
                </span>
              </h1>
            )}

            <div className="flex gap-3 mt-3">
              <Button lg variant="outline">
                <img src={twitterIcon} alt="icon" />
                <a
                  href="https://twitter.com/SuperFlareCoin"
                  target="_blank"
                  rel="noreferrer"
                >
                  SUPERFLR
                </a>
              </Button>
              <Button lg variant="outline">
                <img src={telegramIcon} alt="icon" />
                <a
                  href="https://t.me/SuperFlarecoin"
                  target="_blank"
                  rel="noreferrer"
                >
                  SUPERFLR
                </a>
              </Button>
            </div>
          </div>
          <div className="mint_left_content">
            <div className="mint_left_inner">
              <div className="mint_slider">
                <SliderNFT />
              </div>
              <ul className="mint_count_list z-50">
                <li>
                  <h3 className="font-extrabold text-white">Minted</h3>
                  <h3 className="font-extrabold text-white">
                    {mintCount} / {config.MaxMintCount}
                  </h3>
                </li>
                <li>
                  <h3 className="font-extrabold text-white">Price</h3>
                  <h3 className="font-extrabold text-white">50M SUPERFLARE</h3>
                </li>
              </ul>
              {mintState && (
                <Button lg onClick={() => mint()} variant="outline">
                  {' '}
                  Mint Super Creatures
                  {loadingState && (
                    <span className="mx-3">
                      <GooSpinner size={27} />
                    </span>
                  )}
                </Button>
              )}{' '}
              <br />
              <Button lg variant="outline">
                <a
                  href="https://app.blazeswap.xyz/pool/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Swap SUPERFLR
                </a>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </MintStyleWrapper>
  )
}

export default Mint
